import { SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useUserContext } from '../../../contexts/UserContext';

import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { TextInput } from '../../atoms/Form/TextInput';
import { Copy } from '../../atoms/Typography';
import { ChevronRightIcon } from '../../atoms/Icons';

export const InviteUserOnBoardModal = ({ handleCancel, handleSubmit }: InviteUserOnBoardModalProps) => {
  const user = useUserContext();

  const onSubmit: SubmitHandler<FormData> = async ({ email, subject, content }) => {
    await handleSubmit({ email, subject, content });
  };

  return (
    <Wrapper>
      <H2>Invite Recruiter</H2>
      <InfoAlert>
        <Copy marginBottom={16}>
          Empower your recruitment process by inviting skilled recruiters/agents to collaborate on your Jobs Board.
        </Copy>
        <Copy styleLevel={2}>
          Recruiters/agents are not only able to access the latest CVs you've uploaded but can also contribute by adding
          new opportunities that align with your requirements.
        </Copy>
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <TextInput name="email" fullWidth type="email" label="Email" required noMargin={true} />
        <TextInput
          name="subject"
          fullWidth
          type="text"
          label="Subject"
          required
          defaultValue={`${user?.nickname} has invited you on JobsBoard.io`}
          noMargin={true}
        />
        <TextInput
          name="content"
          fullWidth
          type="text"
          label="Content"
          multiline
          required
          rows={5}
          defaultValue={`You've been invited by ${user?.nickname} [${user?.email}] to collaborate on creating a job opportunity on JobsBoard.`}
          noMargin={false}
        />
        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>

          <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
            Invite
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email('The email is not valid').max(50, 'Too Long!'),
  subject: Yup.string().required().min(10, 'Too short').max(50, 'Too Long!'),
  content: Yup.string().required(),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface InviteUserOnBoardModalProps {
  handleSubmit: (a: any) => void;
  handleCancel: (a: any) => void;
}
